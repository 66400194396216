@import "src/assets/styles/variables";

.input-label,
.textarea-label,
.select-label,
.autocomplete-label,
.input-file-label,
.readonly-label,
.check-label,
.input-date-label {
  label {
    color: #797979;
    font-size: toRem(13);
    position: relative;
    align-self: start;
    margin-top: 0.4rem;

    .required {
      display: inline-block;
      color: $danger;
      position: absolute;
      top: 0;

      .requiredRight {
        right: 0.25rem;
      }
    }
  }

  input, select, textarea {
    border: 1px solid #D8D6DE;
    border-radius: 5px;
    font-size: toRem(14);
  }

  ::placeholder {
    color: #B9B9C3;
  }

  .invalid-feedback {
    font-size: toRem(12);
  }
}

.autocomplete-label {
  input {
    width: 100%;
    padding: 0.375rem 0.75rem;

    &:focus {
      border-color: #9ca6b9;
      outline: 0;
      box-shadow: 0 0 0 0.25rem rgba(57, 77, 114, 0.25);
    }
  }

  &.disabled input {
    cursor: default;
    background-color: rgba(#D8D6DE, 0.5);
    caret-color: transparent;

    &:focus {
      box-shadow: none;
      border-color: #D8D6DE;
    }
  }

}

.react-autosuggest__container {
  position: relative;

  .react-autosuggest__suggestions-container {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: $white;
    border: 1px solid #D8D6DE;
    border-radius: 0 0 5px 5px;
    font-size: toRem(14);
    display: none;
    z-index: 80;

    &.react-autosuggest__suggestions-container--open {
      display: block;
    }

    ul {
      margin: 0;
      list-style-type: none;
      padding: 0;

      li {
        padding: 0.25rem 0.75rem;

        &:hover {
          cursor: pointer;
          background-color: rgba(0, 0, 0, 0.025);
        }
      }
    }
  }
}

.input-file {
  &.is-invalid {
    .input-group-text {
      border-color: red
    }
  }

  .form-control {
    background-color: transparent;
  }

  &.readonly {
    cursor: default;

    .form-control {
      background-color: #e9ecef;
      cursor: default;
    }
  }
}

.readonly-label {
  .readonly {
    font-size: toRem(14);
    margin-top: 0.4rem;
    margin-left: 0.4rem;
  }
}

.search-input {
  border-radius: 5px;
  border: 1px solid #D8D6DE;

  &:focus-within {
    border-color: #9ca6b9;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(57, 77, 114, 0.25);
  }

  .input-group-text {
    background-color: transparent;
    border: none;
    font-size: toRem(14);

    svg {
      color: #A2A2A2;
    }
  }

  .form-control {
    border: none;
    font-size: 0.9rem;

    ::placeholder {
      font-size: toRem(14);
      color: #B9B9C3;
    }

    &:focus {
      border: none;
      box-shadow: none;
    }
  }
}

.date-input {
  .date-picker {
    .react-date-picker__wrapper {
      display: none;
    }

    .react-calendar {
      font-size: 1rem;
    }
  }

  .form-control {
    background-color: transparent;

    &.is-invalid {
      background: none;
    }
  }

  .icons {
    .btn {
      background: none;
      border: none;
      color: $dark;

      &:hover {
        background-color: rgba($dark, 0.2);
      }
    }
  }

  &.editable-date {
    &:not(.editing) .empty-date {
      opacity: 0;
      transition: opacity 0.25s ease-in-out;
    }

    .icons {
      opacity: 0;
      transition: opacity 0.25s ease-in-out;
    }

    &:hover {
      &:not(.editing) .empty-date {
        opacity: 1;
      }

      .icons {
        opacity: 1;
      }
    }
  }
}
