@import '/src/assets/styles/variables.scss';

.app-dropdown {
  border: 1px solid $gray;
  border-radius: 3px;
  background-color: $white;
  box-shadow: 0 2px 6px rgba($gray, 0.3);

  .dropdown-toggle, .dropdown-toggle:active {
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: $dark;
    display: flex;
    align-items: center;

    &::after {
      content: none;
    }

    .icon-arrow {
      transform: rotateZ(180deg);
      transition: transform 500ms ease;
    }
  }

  .dropdown-menu {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    border-radius: 0;
  }

  &.expanded {
    .icon-arrow {
      transform: rotateZ(0);
    }
  }
}