@import "src/assets/styles/variables";

.toast-container {
  z-index: 10000;

  .toast {
    margin-top: 0.5rem;
    background-color: $white;

    &.success {
      border: 1px solid $success;

      .toast-body {
        background-color: rgba($success, 0.3);
      }
    }

    &.error {
      border: 1px solid $danger;

      .toast-body {
        background-color: rgba($danger, 0.3);
      }
    }

    &.info {
      border: 1px solid $warning;

      .toast-body {
        background-color: rgba($warning, 0.3);
      }
    }

    button.close {
      display: none;
    }
  }
}
