@import "variables";

html body, body #root {
  margin: 0;
  font-size: $font-size-body + px;
  font-family: $fontFamily;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div#body {
  margin: 1.5rem 1rem;
}

footer {
  font-size: toRem(12);
}

.app-version {
  margin-bottom: 10px;
  font-size: toRem(12);
  font-style: italic;
}

.overflow-scroll-x {
  overflow-x: auto;
  overflow-y: hidden;
}

.overflow-scroll-x-visible-y {
  overflow-x: auto;
  overflow-y: visible;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.enable-line-break {
  white-space: pre-line;
}

html .btn.btn-success,
#root .btn.btn-success {
  color: $white;

  &:hover, &:disabled, &:active, &:focus {
    color: $white;
  }
}

html .btn.btn-danger,
#root .btn.btn-danger {
  color: $white;

  &:hover, &:disabled, &:active, &:focus {
    color: $white;
  }
}

fieldset > legend {
  color: #626262;
  font-weight: 600;
  font-size: toRem(15);
}

#root .card {
  border: none;
  border-radius: 10px;
  background-color: $white;
  box-shadow: 0 4px 24px rgba(34, 41, 47, 0.1);

  .card-header {
    background-color: $white;
    border: none;
    border-radius: 10px 10px 0 0;
    padding: 20px;
  }

  .card-title {
    color: $primary;
    font-size: toRem(18);
    font-weight: 600;
    padding-left: 10px;
  }

  .card-body {
    padding: 0;
  }

  .card-footer {
    background-color: $white;
    border: none;
    border-radius: 0 0 10px 10px;
    padding: 20px;
  }
}

input.form-control {
  font-size: toRem(14);
}

.is-invalid ~ .invalid-feedback,
.invalid-feedback.small-text {
  font-size: toRem(12);
}

.btn.small-button {
  font-size: toRem(12);
  font-weight: bold;
  border-radius: 4px;
}


.crop-image {
  position: relative;
  border: 2px dashed transparent;
  border-radius: 4px;
  height: 94px;

  .label-click-to-choose {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 16px;
    background-color: rgba($white, 0.75);
    color: $accent;
    text-align: center;

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .img-preview {
    width: 160px;
    height: 90px;
    overflow: hidden;
  }

  img {
    height: 100%;
  }

  &:hover, &.no-logo.no-image {
    background-color: $light;
    border-color: $accent;

    .label-click-to-choose {
      display: block;
    }
  }
}

.crop-container > div {
  background-color: $light;
  width: 100%;
  height: 150px;
}

.tooltip hr {
  margin: 4px 0;
}

.badge {
  background-color: $primary;

  &.new {
    background-color: $info;
  }

  &.inProgress {
    background-color: $secondary;
  }

  &.validated {
    background-color: $accent;
  }

  &.closed {
    background-color: $danger;
  }
}

.nav-tabs.card-header-tabs {
  border-bottom: 2px solid #EBE9F1;

  .nav-item.nav-link {
    width: 250px;
    max-width: 100%;
    margin-right: 10px;
    border-radius: 5px 5px 0 0;
    border: 2px solid #EBE9F1;
    border-bottom: none;
    text-align: center;

    &.active {
      background-color: $primary;
      color: $white;
      border-color: $primary;
    }

    &.large {
      width: 325px;
    }
  }
}

.tab-content {
  padding: 10px 0;
}

.extended-label {
  line-height: 1.2;

  .small-text {
    font-weight: bold;
    font-style: italic;
    font-size: toRem(11);
  }
}

.rotate-45 {
  transform: rotateZ(45deg);
}

.custom-horizontal-scrollbar {
  /* Scrollbar Mozilla firefox */
  scrollbar-color: $lightGray transparent;
  scrollbar-width: thin;
}

/* Global Scrollbar */
.custom-horizontal-scrollbar::-webkit-scrollbar {
  height: 6px;
}

/* Ascenseur Scrollbar */
.custom-horizontal-scrollbar::-webkit-scrollbar-thumb {
  background: $lightGray;
  border-radius: 6px;
}

@media (min-width: 900px) {
  .btn-right-container {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}

@media (min-width: 1400px) {
  .indicators-container {
    min-width: 45%;
  }
}
