@import '/src/assets/styles/variables.scss';

header {
  color: $dark;

  .over-title {
    color: $gray;
    font-weight: 600;
    font-size: toRem(14);
    line-height: 1;
  }

  h1, .header-title {
    color: $dark;
    font-weight: 600;
    font-size: toRem(28);
    margin-bottom: 0;
    line-height: 1;
  }

  .editable-text, .editable-date {
    max-width: 100%;

    .editable-h1, .hidden-ref {
      color: $dark;
      font-weight: 600;
      font-size: toRem(28);
      padding: 0 4px;
      line-height: 1;
    }
  }
}

.header-text-label {
  max-width: 100%;

  .label {
    color: $gray;
    font-size: toRem(9);
    font-weight: 600;
    line-height: 1;
    opacity: 0.7;
  }

  .status-container .status-component {
    & > div {
      padding: 8px 16px;
    }
  }
}

.min-width-45 {
  min-width: 45%;
}

@media (min-width: 1400px) {
  .header-title-container {
    width: 27.5%;
  }
}