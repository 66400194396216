@import "src/assets/styles/variables";

.editable-text, .editable-textarea, .editable-select, .editable-date {

  input, textarea, select, .hidden-ref {
    font-size: toRem(13);
    color: $gray;
    font-weight: 500;
    background-color: transparent;
    border-radius: 4px;
    border: 1px solid transparent;
    padding-top: 4px;
    padding-bottom: 4px;

    &.is-invalid {
      background-image: none;
    }

    &.color-danger {
      color: $danger;
    }
  }

  select {
    background-size: 0;
  }

  .btn-actions {
    position: absolute;
    top: 50%;
    right: 4px;
    transform: translateY(-50%);
    display: none;
  }

  .invalid-feedback {
    font-size: toRem(11);
  }

  &:hover, &.editing {
    input:not(.is-invalid),
    select:not(.is-invalid),
    textarea:not(.is-invalid) {
      background-color: $white;
      border: 1px solid #CFCFCF;
    }

    select {
      background-size: 16px 12px;
    }
  }

  &.date {
    &:not(.editing) .empty-date {
      color: transparent;
    }

    input[type="date"] {
      line-height: normal;

      &::-webkit-inner-spin-button,
      &::-webkit-calendar-picker-indicator {
        opacity: 0;
        width: 1em;
        height: 1em;
      }
    }
  }

  &.editing {

    .btn-actions {
      display: block;
    }

    &.date {
      input[type="date"] {
        &::-webkit-inner-spin-button,
        &::-webkit-calendar-picker-indicator {
          opacity: 1;
        }
      }
    }
  }
}

.editable-textarea {
  .btn-actions {
    bottom: 4px;
    top: auto;
    transform: translateY(0);
  }
}

.hidden-ref {
  position: absolute;
  visibility: hidden;
  white-space: nowrap;
}

.color-danger {
  color: $danger;
}