@import "src/assets/styles/variables";

.text-accent {
  color: $accent;
}

.transfer-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#table-controls-lot-list-container th {
  padding-left: 6px;
  padding-right: 6px;
}

#table-controls-lot-list-container tr {
  padding-left: 6px;
  padding-right: 6px;
}

.attachFile-createdDate {
  width: 20%
}

.attachFile-originalFileName {
  width: 60%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.documents-list-container {
  max-height: 400px;
  overflow-y: auto;
}

.actions {
  text-align: center;

  .btn {
    border: none;
    min-width: 30px;

    &:hover {
      background-color: rgba(33, 40, 55, 0.2);
    }
  }
}