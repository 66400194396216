@import "src/assets/styles/variables";

#root .card-tabs {
  .card-body {
    color: $gray;
    font-weight: 500;
    font-size: toRem(13);

    .nav-tabs .nav-link {
      border-radius: 0;
      background-color: rgba(0, 0, 0, 0.05);
      box-shadow: inset -4px -4px 10px -10px black;

      &:first-child {
        border-top-left-radius: 10px;
      }

      &:last-child {
        box-shadow: inset 4px -4px 10px -10px black;
        border-top-right-radius: 10px;
      }

      &.active {
        background-color: white;
        box-shadow: none;
      }
    }

    .tab-content {
      padding-top: 0;

      .table-striped tbody tr:nth-of-type(odd) {
        --bs-table-striped-bg: white;
      }

      .table-striped tbody tr:nth-of-type(even) {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }

    table {
      margin-bottom: 8px;
    }

    td.head {
      color: $primary;
      font-weight: 600;
      padding-left: toRem(30);
      padding-top: toRem(8);
    }

    td:not(.head) {
      & > :not(.editable-text):not(.editable-textarea):not(.editable-date) {
        padding: toRem(4) toRem(12);
      }
    }
  }
}
