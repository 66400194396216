@import "src/assets/styles/variables";

.circle {
  $size: 20px;
  border-radius: $size;
  width: $size;
  height: $size;
  margin: auto;

  &.active {
    color: $green;
    background-color: $lightGreen;
  }

  &.editInProgress {
    color: $darkPurple;
    background-color: $purple;
  }


  &.inactive {
    color: $accent;
    background-color: rgba($accent, 0.2);
  }
}


.tooltip-sub-status-component {
  .tooltip-arrow {
    left: 0;
    width: 0;
    height: 0;
    border-bottom: 6px solid transparent;
    border-top: 6px solid transparent;
    border-right: 8px solid $dark;
  }

  .tooltip-inner {
    background-color: $dark;
  }
}

