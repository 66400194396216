@import "src/assets/styles/variables";

.filter-statusLot {
  font-weight: bold;
  text-transform: uppercase;


  .new {
    color: $info;
    background-color: rgba($info, 0.2);
  }

  .active, .primary, .compliant {
    color: $green;
    background-color: $lightGreen;
  }

  .inProgress {
    color: $darkPurple;
    background-color: $purple;
  }

  .notCompliant {
    color: $danger;
    background-color: rgba($danger, 0.2);
  }

  .interrupted {
    color: $darkGray;
    background-color: rgba($darkGray, 0.2);
  }

  .validated {
    color: $accent;
    background-color: rgba($accent, 0.2);
  }
}