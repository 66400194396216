@import "src/assets/styles/variables";

.card .tab-content .title {
  color: $primary;
  font-weight: bold;
}

.card-control-plan {
  .remote-table tr .editable-select.compliant .form-select,
  .remote-table tr .editable-select.source .form-select {
    font-size: 0.875rem;
    font-weight: bold;
  }

  .remote-table tr .editable-select.compliant .form-select option,
  .remote-table tr .editable-select.source .form-select option {
    font-weight: normal;
  }
}

.modal-transfer {
  table {
    $row-height: 40px;

    thead tr th {
      height: $row-height;
    }

    tbody tr td {
      height: $row-height;

      &:nth-child(2),
      &:nth-child(3) {
        max-width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:nth-child(3) {
        max-width: 150px;
      }
    }
  }

  .buttons-container .btn {
    min-width: 80px;
  }
}

.vertical-arrow-divider {
  $border-width: 1px;
  $divider-color: $gray;
  position: relative;
  height: 100%;

  &::before {
    content: '';
    width: 0;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    border-right: $border-width solid $divider-color;
    transform: translateX(-$border-width);
  }

  &::after {
    $arrow-size: 20px;
    content: '';
    width: $arrow-size;
    height: $arrow-size;
    border: solid $divider-color;
    border-width: 0 $border-width $border-width 0;
    background-color: $white;
    transform: translate(-$arrow-size / 2, -$arrow-size / 2) rotateZ(-45deg);
    position: absolute;
    top: 50%;
    left: 50%;
  }
}

.closing-date-component {
  .react-date-picker__wrapper {
    display: none;
  }

  &:not(.cursor-pointer) {
    .dropdown-toggle {
      cursor: default;
      padding-left: 0 !important;
    }

    .app-dropdown {
      border: none;
      background-color: transparent;
      box-shadow: none;
    }
  }
}

.transfer-from {
  font-weight: bold;
  white-space: nowrap;
  border-radius: 10px;

  &:hover {
    background-color: rgba($gray, 0.1);
  }
}

table tbody tr td .actions {
  min-width: 160px;
}

.tooltip-analysis-comment {
  position: absolute;
  right: calc(100% + 8px);
  top: 50%;
  transform: translateY(-50%);
  padding: toRem(4) toRem(8);
  border-radius: 7px;
  background-color: $dark;
  box-shadow: 0 2px 6px rgba($gray, 0.3);
  border: 1px solid rgba($dark, 0.3);

  &:focus-within, &.empty {
    background-color: $white;
    border: 1px solid rgba($gray, 0.3);

    &::before {
      border-left-color: rgba($gray, 0.3);
    }
  }

  &:focus-within {
    box-shadow: 0 0 0 0.25rem rgba(60, 78, 113, 0.25);

    input {
      box-shadow: none;
    }
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    right: -8px;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-bottom: 6px solid transparent;
    border-top: 6px solid transparent;
    border-left: 8px solid $dark;
  }

  .editable-text, .readonly-comment {
    min-width: 90px;
    max-width: 315px;
    padding: 0;

    input {
      background-color: $dark !important;
      color: $white !important;
      border: none !important;
      padding: 0;

      &:focus, &.empty {
        background-color: $white !important;
        color: $dark !important;
      }
    }
  }

  .readonly-comment {
    background-color: $dark;
    color: $white;
    padding: 0;
    width: auto;
    white-space: nowrap;
    overflow-x: auto;
  }
}

.destination-lot {
  &.disabled {
    border: none;
    box-shadow: none;
    background-color: transparent;
  }

  .destination-lot-item {
    &:hover {
      background-color: rgba($gray, 0.1);
    }

    & > div {
      font-weight: bold;
      font-size: toRem(14);
    }
  }
}

@media (min-width: 900px) {
  .btn-right-container {
    position: static;
  }

  .card-lot-indicators .card-body .row .col {
    max-width: 300px;
  }
}

@media (min-width: 1200px) {
  .btn-right-container {
    position: absolute;
  }
}

@media (min-width: 1800px) {
  .tooltip-analysis-comment {
    .editable-text {
      max-width: 350px;
    }
  }
}

@media (min-width: 2000px) {
  .tooltip-analysis-comment {
    .editable-text {
      max-width: 400px;
    }
  }
}

@media (min-width: 2250px) {
  .tooltip-analysis-comment {
    .editable-text {
      max-width: 450px;
    }
  }
}

@media (min-width: 2500px) {
  .tooltip-analysis-comment {
    .editable-text {
      max-width: 500px;
    }
  }
}
