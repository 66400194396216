@import "src/assets/styles/variables";

.confirm-dialog {

  .modal-content {
    border-radius: 10px;
    overflow: hidden;

    .modal-header {
      color: #626262;
      background-color: #F8F8F8;
      border: none;

      .modal-title {
        font-weight: 600;
        font-size: 1rem;
      }
    }

    .modal-footer {
      border: none;
    }
  }
}