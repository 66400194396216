@import "src/assets/styles/variables";

#root .card-documents {
  .card-header {
    padding-bottom: toRem(16);
    padding-left: toRem(30);

    .card-title {
      margin: 0;
      padding: 0;
      color: $primary;
      font-weight: 600;
      font-size: toRem(18);
    }
  }

  .card-body {
    color: $gray;
    font-weight: 500;
    font-size: toRem(13);

    table {
      font-family: Roboto, sans-serif;
      margin-bottom: 8px;
      vertical-align: middle;

      tr {
        height: 50px;
      }

      .document-item {
        padding-left: 30px;

        .document-name {
          color: #212837;
          font-weight: 600;
          font-size: toRem(14);
        }

        .document-description {
          color: $gray;
          font-size: toRem(12);
          line-height: 1;
        }
      }

      .createdDate {
        width: 100px;
        text-align: center;
        color: #6F6F6F;
      }

      .actions {
        width: 60px;
        text-align: center;

        .btn {
          background: none;
          border: none;
          color: #212837;
          min-width: 30px;

          &:hover {
            background-color: rgba(33, 40, 55, 0.2);
          }
        }
      }
    }

  }
}