@import "src/assets/styles/variables";

.remote-table,
.local-table {
  div.error {
    min-height: 130px;
  }

  .size-selector {
    font-family: Roboto, sans-serif;
    font-size: 0.8rem;
    color: #6E6B7B;

    .form-select {
      color: #6E6B7B;
      font-size: 0.9rem;
      background-size: 10px 10px;
      padding-right: 2rem;
    }
  }

  .table {

    &,
    thead,
    tbody,
    td,
    tr,
    td,
    th {
      border: none;
      vertical-align: middle;

      .rawMaterial {
        width: 100%;
        display: block;
        padding-left: 2%;
        padding-right: 4%;

        .title {
          margin-top: 5px;
          margin-bottom: 5px;
          font-weight: bold;
          font-size: toRem(18);

        }

        .table {
          font-size: toRem(15);
          color: $dark;
          font-family: "Montserrat", Roboto, sans-serif;

          .icon{
            margin-left :  50%;
          }
          tr {
            background-color: green;
            border-bottom: 1px solid #EBE9F1;
            margin-left: 10%;
          }

          td {
            border-bottom: 1px solid #EBE9F1;
            margin-left: 10%;
          }

          .btn {
            background: none;
            border: none;
            color: $dark;

            &:hover {
              background-color: rgba($dark, 0.2);
            }

            &.edit:hover {
              color: $accent;
              background-color: rgba($accent, 0.2);
            }

            &.delete:hover {
              color: $red;
              background-color: rgba($red, 0.2);
            }

            &.text-info:hover {
              color: $info;
              background-color: rgba($info, 0.2);
            }

            &.text-danger:hover {
              color: $danger;
              background-color: rgba($danger, 0.2);
            }

            &.comment:hover {
              color: $purple;
              background-color: rgba($purple, 0.2);
            }

          }
        }
      }
    }

    td.aggregated,
    td.left-aggregated {
      background: #F6F4FE;
      font-weight: bold;
      font-size: toRem(15);
      color: $dark;
      font-family: "Montserrat", Roboto, sans-serif;
    }


    tr {
      border-top: 1px solid #EBE9F1;
      border-bottom: 1px solid #EBE9F1;
    }

    th.width-0,
    td.width-0 {
      width: 0;
      max-width: 0;
      padding: 0;
      margin: 0;
    }

    .form-check-input {
      height: 15px;
      width: 15px;
    }

    .form-select {
      padding-top: 4px;
      padding-bottom: 4px;
    }

    thead {
      background-color: #F3F2F7;
      color: #6E6B7B;
      text-transform: uppercase;
      font-size: toRem(12);
      font-weight: 600;

      th {
        vertical-align: middle;
        padding-left: 8px;
        padding-right: 8px;

        &:not(:first-child) {
          border-left: 1px solid white;
        }

        .sortable-column {
          font-size: 0;

          div {
            font-size: toRem(12);
          }

          .order-icon {
            margin-left: 4px;

            &.order-none {
              opacity: 50%;
            }
          }
        }

        &> :not(.sortable-column) {
          cursor: default;
        }
      }
    }

    &.table-striped {
      --bs-table-striped-bg: rgba(0, 0, 0, 0.025);

      &>tbody>tr:nth-child(odd) {
        color: $gray;
        background-color: #FFFFFF;
      }

      &>tbody>tr:nth-child(even) {
        background-color: #FBFBFB;
      }

      &>tbody>tr.ordered td {
        background-color: rgba($blue-200, 0.25);
      }

      &>tbody>tr.archived td {
        background-color: rgba($gray, 0.25);
      }

      &>tbody>tr.success td {
        background-color: rgba($success, 0.25);
      }

      &>tbody>tr.danger td {
        background-color: rgba($danger, 0.25);
      }
    }

    tbody {
      color: $gray;
      font-family: Roboto, sans-serif;
      font-size: toRem(14);

      .pastille {
        background-color: rgba($purpleGrey, 0.25);
        text-align: center;
        border-radius: 5px;
        padding: 4px;
        max-width: 120px;
      }

      .pastille-compliance {
        text-align: center;
        border-radius: 5px;
        padding: 4px;
        border: 1px solid $gray;
        box-shadow: 0 0 2px $gray;
        max-width: 120px;

        &.highest {
          border-color: $success;
          box-shadow: 0 0 2px $success;
        }

        &.high {
          border-color: $warning;
          box-shadow: 0 0 2px $warning;
        }

        &.low {
          border-color: $orange;
          box-shadow: 0 0 2px $orange;
        }

        &.lowest {
          border-color: $danger;
          box-shadow: 0 0 2px $danger;
        }
      }

      .actions {
        .btn {
          background: none;
          border: none;
          color: $dark;
          min-width: 30px;

          &:hover {
            background-color: rgba($dark, 0.2);
          }

          &.edit:hover {
            color: $accent;
            background-color: rgba($accent, 0.2);
          }

          &.delete:hover {
            color: $red;
            background-color: rgba($red, 0.2);
          }

          &.text-info:hover {
            color: $info;
            background-color: rgba($info, 0.2);
          }

          &.text-danger:hover {
            color: $danger;
            background-color: rgba($danger, 0.2);
          }

          &.comment:hover {
            color: $purple;
            background-color: rgba($purple, 0.2);
          }
        }
      }
    }
  }

  .pagination {
    .page-item .page-link>* {
      vertical-align: middle;
    }
  }

}

.table.group-by {

  th:first-child,
  td:first-child:not(.aggregated) {
    padding: 0;
  }
}