@import "src/assets/styles/variables";

.warning-alert {
  color: $red;
  font-weight: bold;
  font-size: toRem(13);
  border-radius: 10px;

  &.alert-success {
    color: $green;
  }

  .button-close {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: transparent;

    svg {
      color: $red;
    }

    &:hover {
      background-color: #F2F2F2;
    }
  }
}