@import "src/assets/styles/variables";

.card-indicator {
  min-width: 100%;
  color: $primary;
  font-weight: bold;

  .value {
    font-size: 2rem;
  }

  .year {
    font-size: 0.75rem;
  }
}

@media (min-width: 575px) {
  .card-indicator {
    min-width: 250px;
  }
}

.indicator {
  border: 1px solid rgba($gray, 0.15);
  min-width: 200px;

  .title {
    color: $primary;
    font-weight: bold;
    text-align: center;
  }

  .currentYear {
    color: $primary;
    font-size: 3rem;
    font-weight: bold;
  }

  .lastYear {
    font-size: 0.8rem;

    span {
      color: $purpleGrey;
      font-size: 1.3rem;
      font-weight: bold;
    }
  }
}