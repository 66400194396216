@import '/src/assets/styles/variables.scss';

.navbar {
  .navbar-brand img {
    height: 26px;
  }

  .navbar-nav {
    .nav-link {
      font-weight: 500;
      color: $white;

      &.active {
        background-color: $white;
        color: $primary;
      }
    }

    &.nav-pills {
      .nav-link {
        font-size: 0.8rem;
        font-weight: bold;
        line-height: 1rem;
        border-radius: 5px;
      }
    }
  }
}

#basic-navbar-nav .dropdown .dropdown-menu {
  top: calc(100% + 4px);
  background-color: $primary;
  right: 0.5rem;
  left: auto;

  .dropdown-item {
    color: $white;

    &:hover {
      background-color: $white;
      color: $primary;
    }
  }
}

#menu-profile {
  box-shadow: 0 -5px 24px 0 rgba(#22292F, 0.3);
}

@media (min-width: 992px) {
  #nav-user .nav-item {
    border: none !important;
  }
}