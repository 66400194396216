@import "src/assets/styles/variables";

#document-drop-section {
  .drop-area {
    overflow: hidden;
    flex: 1;

    & > .title {
      text-align: center;
      color: #82868B;
      font-weight: bold;
      font-size: toRem(16);
    }
  }

  .visible {
    border-radius: 10px;
    border: 4px dashed #979797;
  }

  .accepted {
    border-color: $success;
  }

  .rejected {
    border-color: $red;
  }

  &.hovering {
    .drop-area {
      border-color: $accent;

      & > .title {
        color: $accent;
      }
    }
  }
}