@import '/src/assets/styles/variables.scss';

.overlay {
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 100;
}

#nav-user {
  border-top: 1px solid $light;
  padding-top: 0.5rem;

  #avatar {
    border-radius: 50%;
  }

  .nav-item {
    color: $white;
    font-size: 0.9rem;
    font-weight: 500;
  }
}

#menu-profile {
  width: 360px;
  max-width: 100%;

  button.button-close {
    position: absolute;
    top: 16px;
    right: 32px;
  }

  & > .title {
    color: $dark;
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 40px;
  }

  .user-name {
    color: $dark;
    font-weight: 600;
    font-size: 1rem;
  }

  .user-role,
  .user-email {
    color: $gray;
    font-size: 0.8rem;
  }

  .user-email {
    font-style: italic;
  }

  .btn-icon {
    color: $gray;
    background-color: #F8F8F8;
    font-weight: 600;
    font-size: 0.8rem;
    border: none;
    border-radius: 5px;
    overflow: hidden;

    &:hover {
      background: rgba(255, 0, 0, 0.15);
    }

    .icon {
      color: $red;
      background-color: rgba($red, 0.15);
      height: 35px;
      width: 35px;
      position: relative;

      svg, .spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .navbar-nav .nav-link {
    &:hover {
      cursor: pointer;
      background-color: #F2F2F2;
    }

    .icon {
      background-color: #F8F8F8;
      width: 40px;
      height: 40px;
      border-radius: 5px;
      position: relative;

      svg {
        color: #747474;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .title {
      color: #747474;
      font-weight: 600;
      font-size: 0.8rem;
    }

    .description {
      color: #969696;
      font-weight: 400;
      font-size: 0.65rem;
    }
  }
}

@media (max-width: 400px) {
  #menu-profile {
    width: 100%;
  }
}

@media (min-width: 992px) {
  #nav-user {
    border-top: none;
    padding-top: 0;

    .nav-item {
      img + div {
        max-width: 130px;
      }
    }
  }
}

@media (min-width: 1200px) {
  #nav-user {
    .nav-item {
      img + div {
        max-width: 100%;
      }
    }
  }
}