@import "src/assets/styles/variables";

.status-container {
  position: relative;
  display: inline-block;

  .status-component {
    & > div {
      display: inline-block;
      border-radius: 3px;
      font-size: toRem(11);
      font-weight: bold;
      font-family: Roboto, sans-serif;
      text-align: center;
      text-transform: uppercase;
      padding: 4px 16px;
    }

    .monitoring-up-to-date {
      color: $info;
      background-color: rgba($info, 0.2);
    }

    .monitoring-late {
      color: $accent;
      background-color: rgba($accent, 0.2);
    }

    .monitoring-inactive {
      color: $darkGray;
      background-color: rgba($darkGray, 0.2);
    }
  }
}

