@media (min-width: 900px) {
  .btn-right-container {
    position: static;
  }

  .card-product-indicators .card-body .row .col {
    max-width: 350px;
  }
}

@media (min-width: 1200px) {
  .btn-right-container {
    position: absolute;
  }
}
