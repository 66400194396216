@import "src/assets/styles/variables";

.overlay {
  background-color: $white;
}

#pane-analysis {
  box-shadow: 0 -5px 24px 0 rgba(#22292F, 0.3);
  width: 100%;
  max-width: 100%;
  overflow-y: auto;

  .title {
    color: $dark;
    font-size: toRem(16);
    font-weight: 600;
  }

  .button-close {
    right: 8px;
  }

  table {
    border-collapse: separate;
    border-spacing: 0 10px;

    tr.pane-analyse-element {
      td {
        background-color: #FBFBFB;
        border: 1px solid #EBE9F1;

        &:first-child {
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          border-right: none;
        }

        &:last-child {
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          border-left: none
        }

        &:not(:first-child):not(:last-child) {
          border-left: none;
          border-right: none;
        }

        .title {
          color: $gray;
          font-size: toRem(10);
          font-weight: 300;
          font-family: Roboto, sans-serif;
        }

        .value {
          color: $dark;
          font-size: toRem(14);
          font-weight: 600;
          line-height: 1;
        }
      }
    }
  }
}

.warning-text {
  color: $red;
}

#drop-section {
  height: 350px;

  .drop-area {
    border-radius: 10px;
    border: 4px dashed #979797;
    overflow: hidden;
    flex: 1;

    & > .title {
      text-align: center;
      color: #82868B;
      font-weight: bold;
      font-size: toRem(16);
    }

    .dropped-elements {
      overflow-y: auto;

      .button-close {
        top: -8px;
        right: -8px;
      }
    }
  }

  .count-dropped-elements {
    color: #6E6B7B;
    font-size: toRem(14);
    font-weight: 600;
  }

  .actions .btn {
    font-family: Roboto, sans-serif;
    font-size: toRem(15);
    font-weight: bold;
    padding-left: 20px;
    padding-right: 20px;
  }

  &.error {
    .drop-area {
      border-color: $danger;
    }
  }

  &.hovering {
    .drop-area {
      border-color: $accent;

      & > .title {
        color: $accent;
      }
    }
  }
}

.selected-analyse-element {
  background-color: #FBFBFB;
  border: 1px solid #EBE9F1;
  border-radius: 10px;
  height: 200px;
  width: 250px;

  &.use-details {
    height: 250px;
  }

  .title {
    color: $gray;
    font-size: toRem(10);
    font-weight: 300;
    font-family: Roboto, sans-serif;
  }

  .value {
    color: $dark;
    font-size: toRem(14);
    font-weight: 600;
    line-height: 1;
  }
}

@media (min-width: 992px) {
  .overlay {
    background-color: rgba(255, 255, 255, 0.8);
  }

  #pane-analysis {
    width: 500px;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;

    .button-close {
      right: 24px;
    }
  }

  #drop-section {
    position: absolute;
    left: 0;
    right: 500px;
    top: 45%;
    height: 40%;
    padding: 16px;
    display: flex;
    flex-direction: column;

    .drop-area {
      flex: 1;

      .dropped-elements {
        flex: 1;
      }
    }
  }
}