.login-page-form {
  form {
    width: 530px;
    max-width: 100%;
    background-color: #FFFFFF;
    border-radius: 50px;
  }

  img, .login-form, a {
    margin: auto;
    max-width: 70%;
  }

  img {
    max-width: 80%;
  }

  a {
    margin-bottom: 2em;
  }
}