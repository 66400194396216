@import "src/assets/styles/variables";

.icon {
  margin-right: 10px;
}
.card-body{
  color: $gray;
  gap: 10px;
  font-weight: 500;
  font-size: toRem(16);
  margin: 1em 1em 1em 2.5em;
}

.btn-import{
  width: 40%; 
  margin: auto
}

.btn-icon{
  margin-left : 20px;
}

.resultContainer{
  gap: 10px;
  font-weight: bold;

  .error{
    color : $red;
  }
  .infos{
    color : $success
  }
}

.invalid-feedback, .invalid-feedback.small-text {
  font-size: 0.75rem;
}

.input-date-label-unindented {
  display: flex;
  align-items: center;
}

.input-date-label-unindented .form-label {
  margin-bottom: 0;
  margin-right: 8px;
}

.input-date-label-unindented .date-input {
  flex-grow: 1;
}
.input-dateTo-align{
  margin-left: 0.6rem;
}



