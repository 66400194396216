@import "src/assets/styles/variables";

button.button-close {
  background-color: #F2F2F2;
  border-radius: 3px;
  width: 28px;
  height: 28px;
  padding: 0;
  border: none;
  position: relative;
  top: 16px;
  right: 32px;

  svg {
    color: #A5A5A5;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:focus, &:hover {
    background-color: #FCE6E6;

    svg {
      color: $red;
    }
  }
}

.button-icon-validate,
.button-icon-cancel {
  background-color: transparent;
  border: none;
  border-radius: 3px;
  position: relative;
  width: toRem(28);
  height: toRem(28);

  svg {
    color: $success;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover, &:focus {
    background-color: rgba($success, 0.25);
  }
}

.button-icon-cancel {
  svg {
    color: $red;
  }

  &:hover, &:focus {
    background-color: rgba($red, 0.25);
  }
}

.btn-actions {
  .unselected {
    svg {
      color: $gray;
    }
  }

  .selected {
    svg {
      font-size: 1.4rem;
    }
  }

  .button-icon-validate.unselected {
    &:focus, &:hover {
      svg {
        color: $success;
      }
    }
  }

  .button-icon-cancel.unselected {
    &:focus, &:hover {
      svg {
        color: $red;
      }
    }
  }
}

.button-icon-add {
  color: $white;
  padding: toRem(4) toRem(10);

  &:hover, &:focus {
    color: $white;
  }
}

.btn.btn-accent {
  color: #fff;
  background-color: $accent;
  border-color: $accent;

  &:hover {
    color: #fff;
    background-color: $accent-dark;
    border-color: $accent-dark;
  }
}

.button-with-options {
  position: relative;

  .button-option-container {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 0;
    overflow: hidden;
    transition: max-height 600ms ease;
    color: $accent;
    background-color: $white;
    z-index: 70;
    border-radius: 0 0 5px 5px;
    box-shadow: 0 2px 6px rgba($gray, 0.3);
    font-size: toRem(12);

    .button-option:hover {
      color: $accent-dark;
    }

    .button-option.primary {
      color: $gray;
    }
  }

  &:hover {
    .button-option-container {
      max-height: 100px;
    }
  }
}

.button-more-options {
  .dropdown-toggle::after {
    display: none;
  }

  .dropdown-menu {
    margin-top: 10px;
    background-color: $dark;

    &::before {
      content: '';
      position: absolute;
      top: -8px;
      right: 14px;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 8px solid $dark;
    }

    .dropdown-item {
      color: $white;

      &:hover {
        background-color: rgba($white, 0.1);
      }
    }
  }
}