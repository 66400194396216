@import "src/assets/styles/variables";

@media (min-width: 900px) {
  .btn-add-analysis {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .card-laboratory-indicators .card-body .row .col {
    max-width: 300px;
  }
}