@import "src/assets/styles/variables";

.icon-wrapper {
  position: relative;
  float: left;
  margin-right: 0.5rem;
}

.card-lots-not-compliant {
  .badge {
    font-size: 0.5rem;
    background: $danger;
    width: 10px;
    height: 10px;
    margin: 0;
    border-radius: 50%;
    position: absolute;
    bottom: 4px;
    right: -1px;
    padding: 5px;

    .fa-exclamation {
      position: absolute;
      top: 3px;
      right: 3.75px;
    }
  }
}

.card-indicators {
  color: $primary;

  .currentYear {
    font-size: 2.3rem;
    font-weight: bold;
    line-height: 1.2;
  }

  .lastYear {
    font-size: 0.8rem;

    span {
      color: $purpleGrey;
      font-size: 1.3rem;
      font-weight: bold;
    }
  }
}
