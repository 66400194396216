@import "src/assets/styles/variables";

.status-container {
  position: relative;
  display: inline-block;

  .status-component {
    & > div {
      display: inline-block;
      border-radius: 3px;
      font-size: toRem(11);
      font-weight: bold;
      font-family: Roboto, sans-serif;
      text-align: center;
      text-transform: uppercase;
      padding: 4px 16px;
    }

    .new {
      color: $info;
      background-color: rgba($info, 0.2);
    }

    .active, .primary, .compliant {
      color: $green;
      background-color: $lightGreen;
    }

    .inProgress, .editInProgress {
      color: $darkPurple;
      background-color: $purple;
    }

    .interrupted {
      color: $darkGray;
      background-color: rgba($darkGray, 0.2);
    }

    .inactive, .secondary, .validated {
      color: $accent;
      background-color: rgba($accent, 0.2);
    }

    .notCompliant {
      color: $danger;
      background-color: rgba($danger, 0.2);
    }
  }

  .sub-status-component {
    $size: 25px;
    position: absolute;
    top: -$size / 2;
    right: -$size / 2;
    width: $size;
    height: $size;

    img {
      width: $size;
      height: $size;
    }
  }
}

.tooltip-sub-status-component {
  .tooltip-arrow {
    left: 0;
    width: 0;
    height: 0;
    border-bottom: 6px solid transparent;
    border-top: 6px solid transparent;
    border-right: 8px solid $dark;
  }

  .tooltip-inner {
    background-color: $dark;
  }
}
