@import "src/assets/styles/variables";

.analysis-laboratory-table {

  ::placeholder {
    color: #B9B9C3;
  }

  .form-control {
    font-size: toRem(14);
  }

  table {
    vertical-align: middle;
  }
}

.removeButton{
  background-color:#ffd7d7;
  color : $red;
  border : none;
}

.bordered-container {
  border: 2px solid #ccc;
  border-radius: 5px; 
  padding: 15px;
  margin-bottom: 20px;
}
