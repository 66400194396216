$primary: #3C4E71;
$accent: #FC7900;
$accent-dark: #ED6600;
$success: #28C76F;
$green: #147640;
$lightGreen: #A0EFC4;
$lightGray: #aaa;
$gray: #6F6F6F;
$darkGray: #393434;
$dark: #212837;
$red: #EA5455;
$danger: #F42618;
$purpleGrey: #97A0C4;
$purple: #D5B8FC;
$darkPurple: #462C8D;
$info: #00A1FC;

$fontFamily: 'Montserrat', Roboto, sans-serif;

:export {
  primary: $primary;
  accent: $accent;
  accentDark: $accent-dark;
  success: $success;
  green: $green;
  lightGreen: $lightGreen;
  lightGray: $lightGray;
  gray: $gray;
  darkGray: $darkGray;
  dark: $dark;
  red: $red;
  danger: $danger;
  purpleGrey: $purpleGrey;
  purple: $purple;
  darkPurple: $darkPurple;
  info: $info;
  fontFamily: $fontFamily;
}

$font-size-body: 16;

@function toRem($value) {
  $remValue: ($value / $font-size-body) + rem;
  @return $remValue;
}

@import "/node_modules/bootstrap/scss/functions";
@import "/node_modules/bootstrap/scss/variables";
@import "/node_modules/bootstrap/scss/mixins";