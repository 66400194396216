@import "src/assets/styles/variables";

.custom-modal {
  & > .modal-dialog {
    max-width: 1040px;
  }

  &.modal-xl > .modal-dialog {
    max-width: 1500px;
  }

  .modal-content {
    border-radius: 10px;

    .modal-header {
      color: #626262;
      background-color: #F8F8F8;
      border: none;
      position: relative;
      border-radius: 10px 10px 0 0;

      .modal-title {
        font-weight: 600;
        font-size: toRem(16);
      }

      .button-close {
        position: absolute;
        top: -8px;
        right: -8px;
        z-index: 10;
      }
    }

    .modal-footer {
      border: none;

      .btn {
        font-family: Roboto, sans-serif;
        font-size: toRem(15);
        font-weight: bold;
        border-radius: 4px;
        margin-left: 0.75rem;
      }
    }
  }
}